export default function ComingSoonMinimal() {
  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-3xl mx-auto text-center">
        {/* <div className="mb-8 animate-bounce">
          <div className="h-16 w-16 mx-auto bg-purple-600 rounded-full flex items-center justify-center">
            <svg
              className="w-10 h-10 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </div>
        </div> */}
        <div className="bg-gray-50 rounded-2xl p-8 md:p-12 shadow-lg">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-4">
            Coming Soon
          </h1>
          <p className="text-lg md:text-xl text-gray-600 mb-8">
            We&apos;re working hard to bring you something amazing. Stay tuned!
          </p>

        </div>
      </div>
    </div>
  );
}
