import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ChangePasswordPage from './pages/ChangePassword';
import AllNotesPage from './pages/AllNotesPage';
import PricingPage from './pages/PricingPage';
import PublicRoute from './components/PublicRoutes';
import PrivateRoute from './components/PrivateRoutes';
import MainLayout from './components/MainLayout';
import AiStudio from "./pages/AiStudio";
import { Toaster } from "react-hot-toast";
import React, { useContext, useState } from "react";

const ChangePasswordRoute = ({ children }) => {
  const { passwordChangeRequired } = useContext(AuthContext);
  return passwordChangeRequired ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route
            path="/"
            element={
              <MainLayout>
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              </MainLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <MainLayout>
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              </MainLayout>
            }
          />
          <Route
            path="/dashboard/AllNotes"
            element={
              <MainLayout>
                <PrivateRoute>
                  <AllNotesPage />
                </PrivateRoute>
              </MainLayout>
            }
          />
          <Route
            path="/pricing"
            element={
              <MainLayout>
                <PrivateRoute>
                  <PricingPage />
                </PrivateRoute>
              </MainLayout>
            }
          />
          <Route
            path="/dashboard/ai-studio"
            element={
              <MainLayout>
                <PrivateRoute>
                  <AiStudio />
                </PrivateRoute>
              </MainLayout>
            }
          />
        </Routes>
        <Toaster />
      </AuthProvider>
    </Router>
  );
};

export default App;
