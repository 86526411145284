import React, { useEffect, useState } from 'react';
import { fetchNotes } from '../services/noteService';
import ComingSoon from "../components/ComingSoon";
import {
  Tabs,
  TabList,
  TabTrigger,
  TabContent,
} from "../components/TabsComponent";
import { jsPDF } from "jspdf";

const AllNotesPage = () => {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Set current date as default

  const getNotes = async (date) => {
    try {
      const fetchedNotes = await fetchNotes(date); // Pass the date to fetchNotes
      setNotes(fetchedNotes);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    getNotes(selectedDate); // Fetch notes for the current date on page load

    const interval = setInterval(() => {
      getNotes(selectedDate); // Polling to fetch notes every 5 seconds
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedDate]); // Fetch notes whenever selectedDate changes

  const handleNoteClick = (note) => {
    if (note.note_status === "completed") {
      setSelectedNote(note);
      setIsModalOpen(true); // Open the modal when a note is clicked
    }
  };

  const handleCopy = () => {
    if (selectedNote) {
      navigator.clipboard.writeText(selectedNote.notes);
      alert("Note copied to clipboard!");
    }
  };

  const handlePrint = () => {
    window.print(); // This will open the print dialog
  };

  const handleSavePDF = () => {
    const doc = new jsPDF();
    doc.text(selectedNote?.notes || "", 10, 10);
    doc.save("note-details.pdf"); // This will download the content as a PDF
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Update selectedDate when the date is changed
  };

  const filteredNotes = notes.filter((note) =>
    note.patient_id.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const codes = selectedNote?.diagnosis_codes?.split("\n") || [];

  return (
    <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
      <h1 className="text-2xl font-bold mb-4">All Notes</h1>

      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
        <input
          type="text"
          className="border border-gray-300 p-2 rounded w-full sm:w-1/3"
          placeholder="Search by patient ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* Date Picker */}
        <input
          type="date"
          className="border border-gray-300 p-2 rounded"
          value={selectedDate}
          onChange={handleDateChange} // Call handleDateChange when date is changed
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border border-gray-300 bg-gray-100 text-left">
                #
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-100 text-left">
                Patient ID
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-100 text-left">
                Visit Date
              </th>
              <th className="py-2 px-4 border border-gray-300 bg-gray-100 text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredNotes.length > 0 ? (
              filteredNotes.map((note, index) => (
                <tr
                  key={note.visits_note_id}
                  className={`${
                    note.note_status === "completed"
                      ? "cursor-pointer hover:bg-gray-100"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                  onClick={() => handleNoteClick(note)}
                  style={{
                    pointerEvents:
                      note.note_status === "completed" ? "auto" : "none",
                  }}
                  title={
                    note.note_status !== "completed"
                      ? "This note is not clickable"
                      : ""
                  }
                >
                  <td className="py-2 px-4 border border-gray-300">
                    {index + 1}
                  </td>
                  <td className="py-2 px-4 border border-gray-300">
                    {note.patient_id}
                  </td>
                  <td className="py-2 px-4 border border-gray-300">
                    {note.visit_date}
                  </td>
                  <td
                    className={`py-2 px-4 border border-gray-300 ${
                      note.note_status === "completed"
                        ? "text-green-600"
                        : "text-gray-500"
                    }`}
                  >
                    {note.note_status === "completed" ? "Ready" : "Pending"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="py-2 px-4 border border-gray-300 text-center"
                >
                  No notes found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Note Details */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-[90vw] md:w-[85vw] lg:w-[80vw] xl:w-[75vw] p-6 h-[85vh] max-h-[90vh] overflow-y-auto">
            <Tabs defaultValue="note">
              <TabList>
                <TabTrigger value="note">Note Details</TabTrigger>
                <TabTrigger value="diagnosis">Diagnosis</TabTrigger>
                <TabTrigger value="codes">Billing Codes</TabTrigger>
              </TabList>

              <TabContent value="note">
                <div className="mt-4">
                  <h2 className="text-2xl font-bold mb-4">Note Details</h2>
                  <textarea
                    className="w-full p-3 border rounded h-[60vh]"
                    value={selectedNote?.notes || ""}
                    onChange={(e) =>
                      setSelectedNote({
                        ...selectedNote,
                        notes: e.target.value,
                      })
                    }
                  />
                </div>
              </TabContent>

              <TabContent value="diagnosis">
                <div className="mt-4">
                  <h2 className="text-2xl font-bold mb-4">
                    Diagnosis Information
                  </h2>
                  <div className="p-4 border rounded">
                    {codes.length > 0
                      ? codes.map((code, i) => (
                          <div
                            key={i}
                            className={i < codes.length - 1 ? "mb-2" : ""}
                          >
                            {code}
                          </div>
                        ))
                      : "No diagnosis information available"}
                  </div>
                </div>
              </TabContent>

              <TabContent value="codes">
                <div className="mt-4 ">
                  <div className="bg-gray-50 rounded-2xl p-8 md:p-12 shadow-lg">
                    <h1 className="text-2dxl md:text-6xl font-bold text-gray-900 mb-4">
                      Coming Soon
                    </h1>
                    <p className="text-lg md:text-xl text-gray-600 mb-8">
                      We&apos;re working hard to bring you something amazing.
                      Stay tuned!
                    </p>
                  </div>
                </div>
              </TabContent>
            </Tabs>

            <div className="mt-4 flex space-x-2">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={handleCopy}
              >
                <i className="fas fa-copy"></i> Copy
              </button>
              <button
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                onClick={handlePrint}
              >
                Print
              </button>
              <button
                className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700"
                onClick={handleSavePDF}
              >
                Save as PDF
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllNotesPage;
