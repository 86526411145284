// src/components/Sidebar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import SaasproLogo from "../assets/images/saasprologo2.png";
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { logout } = useContext(AuthContext);

  return (
    <div
      className={`fixed inset-y-0 left-0 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out w-64 bg-[#0454F0] text-white p-4 z-50 flex flex-col justify-between lg:relative lg:translate-x-0`}
    >
      <div>
        <img src={SaasproLogo} alt="logo" className="my-8" />
        <ul>
          <li className="mb-4">
            <Link
              to="/dashboard"
              className="block p-2 hover:bg-blue-600 rounded flex items-center"
              onClick={toggleSidebar}
            >
              <i className="fas fa-microphone mr-2"></i>
              {/* FontAwesome icon */}
              Dashboards
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/dashboard/AllNotes"
              className="block p-2 hover:bg-blue-600 rounded flex items-center"
              onClick={toggleSidebar}
            >
              <i className="fas fa-file-alt mr-2"></i> {/* FontAwesome icon */}
              My Scribes
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/dashboard/ai-studio"
              className="block p-2 hover:bg-blue-600 rounded flex items-center"
              // onClick={toggleSidebar}
            >
              <i className="fas fa-star mr-2"></i> {/* FontAwesome icon */}
              AI Studio
            </Link>
          </li>
        </ul>
      </div>
      <div className="mt-auto">
        <button
          className="w-full py-2 px-4 bg-red-600 hover:bg-red-700 rounded"
          onClick={() => {
            toggleSidebar(); // Close the sidebar
            logout(); // Call the logout function
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
