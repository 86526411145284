import { useState, createContext, useContext } from 'react';

export const TabContext = createContext();



export const TabList = ({ children }) => {
  return (
    <div className="flex border-b border-gray-200 w-full">
      {children}
    </div>
  );
};

export const TabTrigger = ({ children, value }) => {
  const { activeTab, setActiveTab } = useContext(TabContext);
  
  return (
    <button
      onClick={() => setActiveTab(value)}
      className={`px-4 py-2 text-sm font-medium transition-colors duration-200 
        ${activeTab === value
          ? 'text-blue-600 border-b-2 border-blue-600'
          : 'text-gray-500 hover:text-gray-700'
        }
      `}
    >
      {children}
    </button>
  );
};

export const TabContent = ({ children, value }) => {
  const { activeTab } = useContext(TabContext);
  
  if (activeTab !== value) return null;
  
  return (
    <div className="mt-4">
      {children}
    </div>
  );
};

export const Tabs = ({ children, defaultValue }) => {
  const [activeTab, setActiveTab] = useState(defaultValue);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      <div className="w-full">
        {children}
      </div>
    </TabContext.Provider>
  );
};

;