import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalAccessToken } from '../utils/constants';
import { FaMicrophone, FaCheckCircle } from 'react-icons/fa';
import { PlusIcon } from '@heroicons/react/24/solid';
import AWS from 'aws-sdk';


AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});


const DashboardPage = () => {
  const [recordingState, setRecordingState] = useState('initial');
  const [sessionType, setSessionType] = useState('');
  const [recordedUrl, setRecordedUrl] = useState('');
  const [recordingTime, setRecordingTime] = useState(0);
  const [patientName, setPatientName] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false); // New state for tracking submission attempt
  const [template, setTemplate] = useState('');
  const [recordingDate, setRecordingDate] = useState(new Date().toISOString().split('T')[0]);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const chunks = useRef([]);
  const recordingInterval = useRef(null);
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);

  const uploadAudioToS3 = async (file, patientId, visitDate, accessToken) => {
    const s3 = new AWS.S3();

    
    const params = {  
      Bucket: 'scribeaudios',
      Key: file.name, // or generate a unique name
      Body: file,
      ContentType: file.type,
    };
  
    try {
      // Upload the file to S3
      const data = await s3.upload(params).promise();
      console.log('File uploaded successfully:', data);
  

      const response = await fetch('https://api.saasprotech.com/audio', {
        method: 'POST',
        body: JSON.stringify({
          visit_date: visitDate,
          patient_id: patientId,
          filename: file.name,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setRecordingState('transcribing');
        // Simulate transcription time
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setRecordingState('summarizing');
        // Simulate summarization time
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setRecordingState('completed');
        // Redirect to 'All Notes' after processing is complete
        // navigate('/dashboard');
      } else {
        throw new Error('Upload failed');
      }

    } catch (error) {
      console.error('Error uploading file or calling API:', error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://api.saasprotech.com/users/me', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setLoggedInUser(data.username);
        } else {
          //delete localStorage.getItem('accessToken');
          // push to login
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userRole');
          window.location.href = '/login';
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        window.location.href = '/login';
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);


  const drawVisualizer = (dataArray) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const canvasCtx = canvas.getContext('2d');
      if (canvasCtx) {
        const width = canvas.width;
        const height = canvas.height;
        canvasCtx.clearRect(0, 0, width, height);

        const barWidth = (width / dataArray.length) * 2.5;
        let barHeight;
        let x = 0;

        canvasCtx.fillStyle = 'lightgrey';
        canvasCtx.fillRect(0, 0, width, height);

        for (let i = 0; i < dataArray.length; i++) {
          barHeight = dataArray[i];
          canvasCtx.fillStyle = 'violet';
          canvasCtx.fillRect(x, height - barHeight / 2, barWidth, barHeight / 2);
          x += barWidth + 1;
        }
      }
    }
  };

  useEffect(() => {
    if (recordingState === 'recording' && analyserRef.current) {
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateVisualizer = () => {
        if (analyserRef.current) {
          analyserRef.current.getByteFrequencyData(dataArray);
          drawVisualizer(dataArray);
          requestAnimationFrame(updateVisualizer);
        }
      };

      updateVisualizer();
    }
  }, [recordingState]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.fftSize = 256; // Configure the size of the FFT
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });
        if (recordedBlob.size > 0) {
          const url = URL.createObjectURL(recordedBlob);
          setRecordedUrl(url);
          setRecordingState('review');
        } else {
          console.error('Recorded Blob is empty');
        }
        clearInterval(recordingInterval.current);
      };

      mediaRecorder.current.start();
      setRecordingState('recording');
      setRecordingTime(0);
      recordingInterval.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
      setRecordingState('paused');
      clearInterval(recordingInterval.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
      setRecordingState('recording');
      recordingInterval.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && (mediaRecorder.current.state === 'recording' || mediaRecorder.current.state === 'paused')) {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
    clearInterval(recordingInterval.current);
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = (error) => {
        console.error('Error converting Blob to Base64:', error);
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };

  const saveAndContinue = async () => {
    if (!patientName.trim()) {
      setSubmitAttempted(true); // Indicate a submission attempt was made without entering a patient name
      return; // Prevent further execution of this function
    }
    const accessToken = getLocalAccessToken();
    setRecordingState('uploading');
    try {
      const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });


      const generateFilename = (loggedInUser, patientName, recordingDate) => {
        const timestamp = new Date().toISOString();
        return `${loggedInUser}/${patientName}/${recordingDate}/${timestamp}.mp3`;
      };
    
      const filename = generateFilename(loggedInUser, patientName, recordingDate);

      const file = new File([recordedBlob], filename, { type: 'audio/mpeg' });
      uploadAudioToS3(file, patientName, recordingDate, accessToken);

    } catch (error) {
      console.error('Error processing audio:', error);
      setRecordingState('review');
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleRecordNewSession = () => {
    // Reset state to start a new session
    setRecordedUrl('');
    setRecordingState('initial');
    setRecordingTime(0);
    setPatientName('');
    setTemplate('');
    setRecordingDate(new Date().toISOString().split('T')[0]);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
      <div className="w-full max-w-md p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 space-y-8 bg-white rounded-lg shadow relative">
       
        <div>
          <label className="block text-gray-700">Patient Name</label>
          <input
            type="text"
            value={patientName}
            onChange={(e) => {
              setPatientName(e.target.value);
              if (submitAttempted) setSubmitAttempted(false); // Reset submission attempt if user starts typing
            }}
            className={`w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${submitAttempted && !patientName.trim() ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-600 border-gray-300'}`}
            placeholder="Enter patient name"
            required
          />
          {submitAttempted && !patientName.trim() && (
            <p className="text-red-500 text-sm mt-1">Patient's name is required.</p>
          )}
        </div>
        <div>
          <label className="block text-gray-700">Clinical Template</label>
          <select
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            required
          >
            <option value="" disabled>Select template</option>
            <option value="SOAP_NOTE">SOAP NOTE</option>
            <option value="PROGRESS_NOTE">PROGRESS NOTE</option>
            <option value="MEETING_NOTE">MEETING NOTE</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-700">Date of Recording</label>
          <input
            type="date"
            value={recordingDate}
            onChange={(e) => setRecordingDate(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Session Type</label>
          <select
            value={sessionType}
            onChange={(e) => setSessionType(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            required
          >
            <option value="" disabled>
              Select session type
            </option>
            <option value="VIRTUAL" disabled>Virtual</option>
            <option value="IN_PERSON">In-Person</option>
          </select>
        </div>
        <div className="relative">
          <h2 className="text-xl font-bold mb-4">Instructions</h2>
          <ol className="list-decimal list-inside">
            <li>Enter patient name and select template</li>
            <li>Click record and start dictating or speaking with patient</li>
            <li>Stop recording to begin processing note</li>
          </ol>
          {(recordingState === 'uploading' || recordingState === 'transcribing' || recordingState === 'summarizing') && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-100 flex items-center justify-center flex-col">
          <FaCheckCircle className="text-green-500 text-6xl mb-4" />
          <p className="text-lg font-semibold mb-2">Check status in All Notes</p>
          <p className="text-center text-gray-600 mb-4">May take a while for transcription and summarization generation to finish</p>

          {recordingState === 'uploading' && (
            <div className="w-full max-w-md mt-4">
              <button
                disabled
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md flex items-center justify-center space-x-2"
              >
                <span>Uploading...</span>
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 1 1 16 0A8 8 0 0 1 4 12zm2-1h12a2 2 0 1 0-2-2h-8a2 2 0 1 0-2 2z"
                  ></path>
                </svg>
              </button>
            </div>
          )}
        </div>
      )}

        </div>
        {recordingState === 'recording' && (
          <div>
            <canvas
              ref={canvasRef}
              className="w-full bg-gray-200 rounded-md"
              width="640"
              height="100"
            ></canvas>
          </div>
        )}
        <div>
          {recordingState === 'initial' && (
            <button
              onClick={startRecording}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
            >
              <FaMicrophone className="inline-block mr-2" /> Start Recording
            </button>
          )}
          {recordingState === 'recording' && (
            <div className="flex space-x-4">
              <button
                onClick={pauseRecording}
                className="w-full px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 focus:outline-none"
              >
                Pause Recording
              </button>
              <button
                onClick={stopRecording}
                className="w-full px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none"
              >
                Stop Recording
              </button>
            </div>
          )}
          {recordingState === 'paused' && (
            <button
              onClick={resumeRecording}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
            >
              Resume Recording
            </button>
          )}
          {recordingState === 'review' && (
            <div>
              <button
                onClick={saveAndContinue}
                className="w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none"
              >
                Save & Continue
              </button>
              <audio controls className="w-full mt-4">
                <source src={recordedUrl} type="audio/webm" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          {recordingState === 'completed' && (
           <div className="flex justify-center">
           <button
             onClick={handleRecordNewSession}
             className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 flex items-center justify-center gap-2"
           >
             <PlusIcon className="w-5 h-5" />
             Record New Session
            </button>
           </div>
        )}

        </div>
        <div className="text-center mt-4">
          {recordingState === 'recording' && (
            <>
              <div id="waveform" className="w-full mb-4"></div>
              <p>Recording... {formatTime(recordingTime)} <span className="text-red-600">●</span></p>
              <p className="mt-2 text-gray-600">Please do not refresh or close the tab while recording</p>
            </>
          )}
          {recordingState === 'paused' && <p>Recording paused at {formatTime(recordingTime)}</p>}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
